.buttonStyle {
    margin-top: 10px;
    border-radius: 12px;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    font-size: 16px;
    height: 58px;
  }
  
  .buttonStyle:hover {
    margin-top: 10px;
    border-radius: 12px;
    cursor: pointer;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    height: 58px;
  }
  .buttonStyle:disabled {
    margin-top: 10px;
    border-radius: 12px;
    background-color: #554949; /* Green */
    border: none;
    color: white;
    cursor: pointer;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
  }
  