body {
  margin: 0;
  padding: 0;
  /* background: #FFFAFA; */
  background: #FFFFFF;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Custom Change Style Sweet Alert */
.swal-footer {
  text-align: center;
}

/* Custom Change Style MUI Component */

/* Dialog Register Change */
.css-uhb5lp{
  border-radius: 20px!important;
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper{
  border-radius: 20px!important;
}

/* Breakpoints */
@media only screen and (max-width: 600px) {
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    border-radius: 20px!important;
    margin: 0px!important;
    width: 100vw!important;
    margin: 0px!important;
    max-width: none;
    max-height: none;
    overflow-y: hidden!important;
  }
  .css-uhb5lp{
    border-radius: 20px!important;
    margin: 0px!important;
    width: 100vw!important;
    margin: 0px!important;
    max-width: none;
    max-height: none;
    overflow-y: hidden!important;
  }
}