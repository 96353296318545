/* Custom Notification CSS */
.notificationContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    width: 90vw;
    max-width: 300px;
    height: 80vh;
    max-height: 450px;
    text-align: center;
    padding: 30px;
}

.notificationContainer img {
    width: 60%;
    margin-bottom: 50px;
}

.notificationDialogMobile {
    height: 100vh;
    margin-top: 20vh;
}

.notificationContainerMobile {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    width: 90vw;
    max-width: 300px;
    height: calc(100vh - 30vh);
    text-align: center;
    padding: 20px;
    margin-top: -50px;
}

.notificationContainerMobile img {
    width: 60%;
    margin-bottom: 50px;
}
/* End Custom Notification */


/* Custom Confirmation CSS */
.confirmationDialog {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
}
.confirmationContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    width: 90vw;
    max-width: 300px;
    height: 80vh;
    max-height: 400px;
    text-align: center;
    padding: 30px 20px;
}

.sliderLine {
    width: 83px;
    height: 6px;
    background-color: #0000001F;
    outline: none;
    border: none;
    margin-top:-20px;
    margin-bottom: 20px;
    border-radius: 20px;
  }

.confirmationDialogMobile{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    margin-top: calc(100vh - 80vh);
}

.confirmationContainerMobile {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    width: 90vw;
    max-width: 300px;
    height: 80vh;
    max-height: 400px;
    text-align: center;
    padding: 30px;
}