.logoContainer {
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -60px;
}
.logoContainer img {
  width: 50%;
}

/* Breakpoints */
@media only screen and (max-width: 600px) {
  .logoContainer {
    margin-top: -10px;
  }
  .logoContainer img {
    width: 60%;
  }
}
