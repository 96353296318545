.inputOTPContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.otpContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.otpContainer form {
  width: 80%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 40px;
}

.otpContainer form input {
  width: 30px;
  height: 30px;
  border: none;
  border-bottom: 1px solid #d81922;
  text-align: center;
  font-size: 24px;
}

.otpContainer form input:focus{
  border-bottom: 1px solid #d81922;
  outline: none!important;
}

.otpContainer form input::placeholder {
  color: #ED3A3A;
}

.waitInstruction {
  font-size: 12px;
  color: gray;
}

.sendBackOtp {
  font-size: 14px;
  color: rgb(39, 39, 161);
  text-decoration: underline;
  cursor: pointer;
}
.sendBackOtp:hover {
  font-size: 14px;
  color: rgb(31, 31, 128);
  text-decoration: underline;
  cursor: pointer;
}

.btnSendOtp {
  margin-top: 10px;
  background-color: #d81922; /* Green */
  border: none;
  border-radius: 5px;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 16px;
}

.btnSendOtp:hover {
  margin-top: 10px;
  border-radius: 5px;
  background-color: #8d2f2f; /* Green */
  border: none;
  color: white;
  cursor: pointer;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.otpContainer {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}

.descOtp {
  text-align: left;
  margin-top: 30px;
}

.resendOtpTimes {
  color: #CF0000;
}

.kirimUlang {
  color: #CF0000;
  text-decoration: underline;
  font-weight: bolder;
  margin-bottom: 40px!important;
  cursor: pointer;
}

.alertOtp {
  padding: 5px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75%;
  background-color: #ffeb33ab;
  color: #CF6E00;
}
.alertOtp svg {
  margin: 0px 10px;
}

/* Mobile View */
.otpRegisterMobile {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.sliderLine {
  width: 83px;
  border-radius: 20px;
  height: 6px;
  background-color: #0000001F;
  outline: none;
  border: none;
}


.otpContainer input[type=number]::-webkit-inner-spin-button, 
.otpContainer input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.otpContainer input[type=number] {
  -moz-appearance:textfield;
}