.container {
  width: 100%;
  height: 100%;
}

.container form {
  width: 85%;
  padding:5px;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
}

.genderFormContainer {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.genderForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.genderForm p {
  color: rgba(0, 0, 0, 0.6);
}

.genderWarning {
  font-size: 12px;
  margin: 0;
  padding: 0;
  color: red;
  text-align: right;
}

.phoneFormContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.phoneLabel {
  width: 10%;
  margin-right: 10px;
  height: 25px;
  -webkit-padding-end: 20px;
  -moz-padding-end: 20px;
  -webkit-padding-start: 2px;
  -moz-padding-start: 2px;
  background-color: #a3a3a3;
  border: 1px solid #aaa;
  border-radius: 2px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  color: #555;
  font-size: inherit;
  overflow: hidden;
  padding-top: 2px;
  padding-bottom: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.inputPhone {
  width: 80%;
  height: 25px;
  background-color: #ffffff;
  border: 1px solid #aaa;
  border-radius: 2px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  color: #555;
  font-size: inherit;
  margin: 0;
  overflow: hidden;
  padding-top: 2px;
  padding-bottom: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.phoneValidation {
  display: flex;
  justify-content: flex-start;
}
.inputWarning {
  color: red;
  font-size: 12px;
}

.buttonRegistrasi {
  margin-top: 10px;
  background-color: #D81922; /* Green */
  border: none;
  border-radius: 5px;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 16px;
}

.buttonRegistrasi:hover {
    margin-top: 10px;
    border-radius: 5px;
    background-color: #8d2f2f; /* Green */
    border: none;
    color: white;
    cursor: pointer;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}
.buttonRegistrasi:disabled {
    margin-top: 10px;
    border-radius: 5px;
    background-color: #533b3b; /* Green */
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}

.successRegister {
  border: 0.5px solid rgb(141, 97, 91);
}

.syaratContainer {
  display : flex
}

.syaratKetentuan{
  color: #CF0000;
}
.syaratKetentuan:hover {
  color: #CF0000;
  cursor: pointer;
}

.syaratKetentuanContainer {
  width: auto;
  height: 60vh;
  padding: 20px;
}
.otpContainer {
  width: 381px;
  height: 507px;
}

/* Mobile View */
.mobileRegisterContainer{
  display: flex;
  width: 100%;
  height: 100%;
  margin-bottom: calc(100vh - 91vh);
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  overflow-y: auto;
}

.mobileRegisterContainer form{
  width: 90%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}

.sliderLine {
  width: 83px;
  border-radius: 20px;
  height: 6px;
  background-color: #0000001F;
  outline: none;
  border: none;
}
.otpContainerDialog{
  margin-top: calc(100vh - 67vh);
  width: 100vw;
  height: calc(100vh - 30vh);
}
.otpContainerMobile {
  width: 100%;
  height: 100%;
}

/* Syarat Ketentuan */

.innerListSyaratKetentuan {
  list-style-type: none;
}
.innerListSyaratKetentuan li{
  margin-bottom: 5px;
}

/* Register Success */
.successContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.successContainer img {
  margin-top: 64px;
  margin-bottom: 50px;
  width: 207px;
}

.success {
  margin-bottom: 19px;
}

.desc{
  margin-bottom: 27px;
}

.sliderLineSuccess {
  width: 83px;
  height: 6px;
  background-color: #0000001F;
  outline: none;
  border: none;
  border-radius: 20px;
  margin-top: 20px;
  margin-bottom: -20px;
}