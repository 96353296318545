body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: 0;
  background-color: #f5f5f5;
  padding: 20px;
  font-family: 'Open Sans';
}
.card {
  max-width: 600px;
  width: 100%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 40px;
}
.logo {
  /* width: 100px; */
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}
.title {
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
}
.paragraph {
  color: #1d1d1d;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
}