.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 100%;
  display:flex;
  flex-direction: column;
  padding: 20px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  align-items: center;
  justify-content: center;
  margin: 20px;
}

.card h1 {
    margin: 0;
    padding: 0;
}

.card hr{
    width: 100%;
    border: 1px solid rgb(107, 107, 107);
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.container {
  width: 376px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.errorTitle{
    color : rgb(100, 52, 52)
}
.errorMessage{
    font-size: 15px;
}