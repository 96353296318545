.loginContainer {
  width: 90%;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loginContainer form {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
}


.usernameContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.passwordContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 20px;
}

.loginMiddleContainer {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Mobile View */
/* Login */
.containerMobileLogin{
  margin-top: calc(34vh/2);
  width: 100vw;
  height: 100vh;
  position: relative;
}

.mobileLoginSuccess{
  position: relative;
  height: calc(100vh - 30vh);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
/* End Login Mobile View */

/* Register Mobile View Modal Container */
.containerMobileRegister{
  margin-top: calc(100vh - 102vh);
  width: 100vw;
  height: 110vh;
}

.mobileRegisterView{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.mobileContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.sliderLine {
  width: 83px;
  border-radius: 20px;
  height: 6px;
  background-color: #0000001F;
  outline: none;
  border: none;
}
.mobileIconContainer{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.mobileIconContainer .mobileIconLoginSuccess {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobileIconContainer .mobileIconLoginSuccess img{
  width: 30%;
}

.mobileBtnContainer {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

/* Forget Password */
.forgetPassword {
  color:#4D4F5C ;
  text-decoration: none;
}

.forgetPassword:hover {
  color:#818185 ;
  text-decoration: none;
  cursor: pointer;
}

.errorMessage{
  font-size: 12px;
  color: red;
}

.forgetForm {
  width: 25vw;
  max-width: 25vw;
  min-width: 381px;
}
/* End Forget Password */

.loginSuccess {
  width: 381px;
  height: 507px;
}

.loginSuccessContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap:10px
}

.successContainer {
  width: 80%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.userContainer {
    width: 100%;
    height: 70%;
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.iconContainer {
    width: 100%;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.iconContainer img{
    width: 20%;
}

.nameContainer {
  margin-top: 10px;
}

.actionContainer {
  display: flex;
  width: 100%;
  height: 30px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.actionNo {
  width: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 12px;
}
.actionNo:hover {
  width: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 12px;
  background-color: rgb(226, 226, 226);
  border-radius: 0px 0px 0px 20px;
}

.actionContinue {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  cursor: pointer;
  height: 100%;
  font-size: 12px;
}
.actionContinue:hover {
  display: flex;
  background-color: rgb(226, 226, 226);
  border-radius: 0px 0px 20px 0px;
  align-items: center;
  justify-content: center;
  width: 50%;
  cursor: pointer;
  height: 100%;
  font-size: 12px;
}

.activateAccountContainer {
  width: 80%;
  height: 225px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border: 2px solid rgb(150, 150, 150);
}

.forgotPassword{
  cursor: pointer;
}
.forgotPassword p {
  text-decoration: underline;
}

.forgotPasswordBox {
  height: 399px;
  width: 381px;
}

/* Forget Otp */
.forgetOtp {
  width: 381px;
  height: 60vh;
}

/* Forgot Success */
.forgotSuccessContainer{
  width: 381px;
  height: 521px;
}

/* Register */
.registerModalContainer {
  background-color: #ffffff;
  height: 700px;
  width: 467px;
  padding: 20px;
  margin: 10px;
  border-radius: 20px;
  overflow-y: hidden;
}

/* Forgot Password Mobile */
.forgetPasswordContainerMobile{
  width: 100vw;
  height:calc(100vh - 20vh) ;
  margin-top: calc(100vh - 70vh);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.forgotPasswordMobile {
  width: 100%;
  height:calc(100vh - 20vh) ;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.forgetPasswordSuccessContainerMobile{
  width: 100vw;
  height:calc(100vh - 10vh) ;
  margin-top: calc(100vh - 80vh);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.forgotPasswordSuccessMobile {
  width: 100%;
  height:calc(100vh - 10vh) ;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.activationContainerMobile {
  width: 100vw;
  height:calc(100vh - 30vh) ;
  margin-top: calc(100vh - 60vh);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.activationBoxMobile {
  width: 100%;
  height:calc(100vh - 10vh) ;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}


/* Breakpoints */
@media only screen and (max-width: 600px) {
  .loginContainer {
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .loginContainer form {
    width: 90%;
    margin-top: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 300px;
  }
  .loginMiddleContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}