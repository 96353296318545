.selectContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    width: 100%;
}
.selectWarning {
    color: red;
    font-size: 12px;
}