.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container .lupaKataSandi {
  margin-top: 30px;
}

.formContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.formContainer form {
  width: 100%;
}

.buttonKirim {
  margin-top: 70px;
}

.resendOtpTimes {
  color: #cf0000;
}

.alertOtp {
  padding: 5px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75%;
  background-color: #ffeb33ab;
  color: #cf6e00;
}
.alertOtp svg {
  margin: 0px 10px;
}

.kirimUlang {
  color: #cf0000;
  text-decoration: underline;
  font-weight: bolder;
  margin-bottom: 50px;
  cursor: pointer;
}

.otpSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.otpSection form {
  width: 100%;
}

.otpInstruction {
  margin: 20px 0px;
}

.inputContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.showPasswordIcon {
  width: 25px;
  height: 18px;
  cursor: pointer;
}
.showPasswordIcon:hover {
  width: 25px;
  height: 18px;
  cursor: pointer;
  box-shadow: 1px 1px 5px black;
}

.inputForm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}

.inputForm label {
  text-align: left;
}

.inputContainer {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.forgetForm{
    width: 381px;
    height: 399px;
}

.formForgetContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.formForgetContainer form {
    width: 100%;
    text-align: center;
    margin-top: 30px;
}

.formButton {
    margin-top: 20px;
}

/* Forgot Success */
.successContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.successContainer img {
  margin-top: 64px;
  margin-bottom: 50px;
  width: 207px;
}

.success {
  margin-bottom: 19px;
}

.desc{
  margin-bottom: 27px;
}

/* Forgot Password Mobile */
.forgetPasswordMobile{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}


.formContainerMobile {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.sliderLine {
  width: 83px;
  height: 6px;
  background-color: #0000001F;
  outline: none;
  border: none;
  margin-bottom: 40px;
}

.sliderLineOtp {
  width: 83px;
  height: 6px;
  background-color: #0000001F;
  outline: none;
  border: none;
}

.formContainerMobile .formContainerTitle{
  padding-left: 20px;
  text-align: left;
  
}

.formContainerMobile form{
  width: 90%;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.formContainerMobile .buttonKirim{
  margin-top: 100px;
}

/* Forgot Password Otp Mobile */
.container .lupaKataSandiMobile {
  margin-top: 30px;
}
.otpInstructionMobile {
  margin-top: 30px;
  margin-bottom: 20px;
}
.btnOtpMobile {
  margin-top:30px;
}

/* Forgot Password Form Mobile */
.formForgetContainerMobile {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.formForgetContainerMobile .lupaKataSandi {
  margin-top: 30px;
}
.formForgetContainerMobile form {
  width: 90%;
  text-align: center;
  margin-top: 60px;
}

.formButtonMobile {
  margin-top: 60px;
}

.sliderLineForm {
  width: 83px;
  height: 6px;
  background-color: #0000001F;
  outline: none;
  border: none;
  margin-top: 20px;
}

/* Forget Password Success Mobile */
.sliderLineSuccess {
  width: 83px;
  height: 6px;
  background-color: #0000001F;
  outline: none;
  border: none;
  border-radius: 20px;
  margin-top: 20px;
  margin-bottom: -20px;
}