.inputContainer { 
    width: 100%;
    margin: 5px;    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
}

.inputWarning {
    color: red;
    font-size: 12px;
    text-align: left;
}
