.App {
  width: 100%;
  height: fit-content;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.appBox {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 466px;
  height: fit-content;
  min-height: 100vh;
  /* background: #FFFAFA; */
  background: #FFFFFF;
  box-shadow: 15px 15px 29px 2px #dadada;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.headerContainer {
  width: 90%;
  display: flex;
  margin: 20px;
  align-items: flex-start;
}

.title {
  /*font-family: Open Sans;*/
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0px;
}

.contentTitle{
  /*font-family: Roboto;*/
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.25px;

}
.logoContainer {
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -60px;
}
.logoContainer img {
  width: 50%;
}
/* Breakpoints */
@media only screen and (max-width: 600px) {
  .appBox {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 0px;
    width: 100vw;
    height: 100vh;
    /* background: #FFFAFA; */
    background: #FFFFFF;
    overflow: hidden;
  }
  .headerContainer {
    width: 100%;
    display: flex;
    margin: 30px;
    padding-left: 70px;
    align-items: flex-start;
  }
  
}